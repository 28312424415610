
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  //重定向操作
  {path:'/home',redirect:'/'},
  {path:'/',component: () => import('../views/Home.vue')},

  {path:'/category',component: () => import('../views/Category.vue')},
  {path:'/categorySearch',component: () => import('../views/CategorySearch.vue')},
  {path:'/terminalMode',component: () => import('../views/terminalMode.vue')},
  {path:'/rcbh',component: () => import('../views/rcbh.vue')},
  {path:'/qhbh',component: () => import('../views/qhbh.vue')},
  {path:'/rwc',component: () => import('../views/rwc.vue')},
  {path:'/csrw',component: () => import('../views/csrw.vue')},
  {path:'/agysbh',component: () => import('../views/agysbh.vue')},
  {path:'/catlist',component: () => import('../views/Catlist.vue')},
  {path:'/productview',component: () => import('../views/productview.vue')},
  {path:'/largeViewImge',component: () => import('../views/largeViewImge.vue')},
  {path:'/news',component: () => import('../views/News.vue'),
    children:[
      // {path:'/',redirect:'/show'},
      // {path:'/show',component: () => import('../components/Show.vue')}
    ]
  },
  {path:'/NewsView',component: () => import('../views/NewsView.vue')},
  {path:'/cart',component: () => import('../views/Cart.vue')},
  {path:'/rwl',component: () => import('../views/rwl.vue')},
  {path:'/settle',component: () => import('../views/Settlement.vue')},
  {path:'/completion',component: () => import('../views/Completion.vue')},
  {path:'/paytype',component: () => import('../views/Paytype.vue')},
  {path:'/CompletionAccount',component: () => import('../views/CompletionAccount.vue')},
  {path:'/PaytypeForOrder',component: () => import('../views/PaytypeForOrder.vue')},
  {path:'/WechatPay',component: () => import('../views/WechatPay.vue')},
  {path:'/CompletionForYuFu',component: () => import('../views/CompletionForYuFu.vue')},
  {path:'/CompletionForHll',component: () => import('../views/CompletionForHll.vue')},
  {path:'/forHll',component: () => import('../views/forHll.vue')},
  {
    path:'/yuFuPay',
    component: () => import('../views/yuFuPay.vue')
  },
  {path:'/customNonStandard',component: () => import('../views/customNonStandard.vue')},
  {path:'/quickOrder',component: () => import('../views/QuickOrder.vue')},
  {path:'/jmbwh_info',component: () => import('../views/mine/jmbwh_info.vue')},
  {path:'/jmbwh_list_info',component: () => import('../views/mine/jmbwh_list_info.vue')},
  {path:'/zljmb_info',component: () => import('../views/mine/zljmb_info.vue')},
  {path:'/zljmb_list_info',component: () => import('../views/mine/zljmb_list_info.vue')},
  {path:'/wwjmbwh_info',component: () => import('../views/mine/wwjmbwh_info.vue')},
  {path:'/wwjmbwh_list_info',component: () => import('../views/mine/wwjmbwh_list_info.vue')},
  {path:'/wwzljmbwh_info',component: () => import('../views/mine/wwzljmbwh_info.vue')},
  {path:'/wwzljmbwh_list_info',component: () => import('../views/mine/wwzljmbwh_list_info.vue')},
  {
    path:'/Mine',
    name:'Mine',
    component: () => import('../views/Mine.vue'),
    meta: {
      keepAlive: true
    },
    children:[
      {path:'/',redirect:'/membercenter'},
      {path:'/membercenter',component: () => import('../views/mine/MemberCenter.vue')},
      {path:'/order',component: () => import('../views/mine/Order.vue')},
      {path:'/wwdd',component: () => import('../views/mine/wwdd.vue')},
      {path:'/cwqr',component: () => import('../views/mine/cwqr.vue')},
      {path:'/cwfk',component: () => import('../views/mine/cwfk.vue')},
      {path:'/cqdd',component: () => import('../views/mine/cqdd.vue')},
      {path:'/cwtk',component: () => import('../views/mine/cwtk.vue')},
      {path:'/cwgbsh',component: () => import('../views/mine/cwgbsh.vue')},
      {path:'/wwcwqr',component: () => import('../views/mine/wwcwqr.vue')},
      {path:'/wwcwfk',component: () => import('../views/mine/wwcwfk.vue')},
      {path:'/wwcwtk',component: () => import('../views/mine/wwcwtk.vue')},
      {path:'/wwcqdd',component: () => import('../views/mine/wwcqdd.vue')},
      {path:'/notice',component: () => import('../views/mine/Notice.vue')},
      {path:'/record',component: () => import('../views/mine/Record.vue')},
      {path:'/invoicing',component: () => import('../views/mine/Invoicing.vue')},
      {path:'/station',component: () => import('../views/mine/Station.vue')},
      {path:'/personal',component: () => import('../views/mine/Personal.vue')},
      {path:'/modify',component: () => import('../views/mine/Modify.vue')},
      {path:'/address',component: () => import('../views/mine/Address.vue')},
      {path:'/children',component: () => import('../views/mine/Children.vue')},
      {path:'/relation',component: () => import('../views/mine/Relation.vue')},
      {path:'/orderdetail',component: () => import('../views/mine/OrderDetail.vue')},
      {path:'/returndetail',component: () => import('../views/mine/ReturnDetail.vue')},
      {path:'/apply',component: () => import('../views/mine/Apply.vue')},
      {path:'/account',component: () => import('../views/mine/AccountList.vue')},
      {path:'/accountdetail',component: () => import('../views/mine/AccountDetail.vue')},
      {path:'/advance',component: () => import('../views/mine/Advance.vue'),
        meta: {
          title: '预付款充值',
          keepAlive: false
        }
      },
      {path:'/customList',component: () => import('../views/mine/customList.vue')},
      {path:'/customdetail',component: () => import('../views/mine/customdetail.vue')},
      {path:'/Complaint',component: () => import('../views/mine/Complaint.vue')},
      {path:'/ComplaintDetail',component: () => import('../views/mine/ComplaintDetail.vue')},
      //日常管理
      {path:'/gysgl',component: () => import('../views/mine/gysgl.vue')},
      {path:'/downCenter',component: () => import('../views/mine/downCenter.vue')},
      //档案管理
      {path:'/gyslb',component: () => import('../views/mine/gyslb.vue')},
      {path:'/qyfs',component: () => import('../views/mine/qyfs.vue')},
      {path:'/slda',component: () => import('../views/mine/slda.vue')},
      {path:'/jyjb',component: () => import('../views/mine/jyjb.vue')},
      {path:'/zthz',component: () => import('../views/mine/zthz.vue')},
      {path:'/jmbwh',name:'jmbwh',component: () => import('../views/mine/jmbwh.vue'),
        meta: {
          title: '供应商价目表',
          keepAlive: true
        }
      },
      {path:'/jmbwh_list',component: () => import('../views/mine/jmbwh_list.vue')},
      {path:'/zljmb',name:'zljmb',component: () => import('../views/mine/zljmb.vue'),
        meta: {
          title: '重量价目表',
          keepAlive: true
        }
      },
      {path:'/zljmb_list',component: () => import('../views/mine/zljmb_list.vue')},
      {path:'/wwjmbwh',name:'wwjmbwh',component: () => import('../views/mine/wwjmbwh.vue'),
        meta: {
          title: '委外加工价目表',
          keepAlive: false
        }
      },
      {path:'/wwjmbwh_list',component: () => import('../views/mine/wwjmbwh_list.vue')},
      {path:'/wwzljmbwh',name:'wwzljmbwh',component: () => import('../views/mine/wwzljmbwh.vue'),
        meta: {
          title: '委外重量价目表',
          keepAlive: false
        }
      },
      {path:'/wwzljmbwh_list',component: () => import('../views/mine/wwzljmbwh_list.vue')},
      {path:'/wwfl',component: () => import('../views/mine/wwfl.vue')},
      {path:'/wwrk',component: () => import('../views/mine/wwrk.vue')},
      {path:'/jmyybb',component: () => import('../views/mine/jmyybb.vue')},
      {path:'/rwzx',component: () => import('../views/mine/rwzx.vue')},
    ]
  },
  //   ]
  // },
  
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/modify',
    component: () => import('../components/logins/modify.vue')
  },
  {
    path: '/register',
    component: () => import('../components/logins/register.vue')
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
